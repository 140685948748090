import { theme } from '@care/material-ui-theme';
import { Box, Grid, useMediaQuery, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  description: string | ReactNode;
  number: number;
  title: string | ReactNode;
  link?: ReactNode;
}

const NumberedTile: FC<Props> = ({ description, number, title, link }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      alignItems={!isMobile ? 'flex-start' : 'center'}
      container
      sx={{
        gap: {
          xs: theme.spacing(2),
          md: theme.spacing(3),
        },
      }}
      direction={isMobile ? 'column' : 'row'}
      justifyContent="center">
      <Box
        sx={{
          backgroundColor: theme.palette.care.red[100],
          borderRadius: '50%',
          color: theme.palette.care.red[400],
          fontSize: '36px',
          fontWeight: 'bold',
          height: '50px',
          padding: theme.spacing(1.5),
          textAlign: 'center',
          width: '50px',
        }}>
        {number}
      </Box>
      <Grid item justifyContent="space-between" sm={8}>
        <Typography
          variant="h3"
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
            margin: theme.spacing(3, 0, 3),
          }}
          color="secondary">
          {description}
        </Typography>
        {link}
      </Grid>
    </Grid>
  );
};

export default NumberedTile;
