import Layout from '@/components/Layout';
import { META_PROPERTIES, PAGE_PROPERTIES, GTM_DATA } from '@/constants';
import useGTMTracking from '@/hooks/useGTMTracking';
import { useUserAuth } from '@/components/UserProvider';
import HowItWorks from '../variant2/HowItWorks';
import Guarantee from './Guarantee';
import RightChoice from './RightChoice';
import NannyHelp from './NannyHelp';
import Help from '../../shared/Help/variant1';
import { HomepageFooter } from './Layout';
import Reviews from './Reviews';
import Hero from '../variant2/Hero';
import Services from '../variant2/Services';

const Homepage = () => {
  const { auth } = useUserAuth();

  useGTMTracking({
    pageProperties: PAGE_PROPERTIES.mainVHP,
    slots: [
      ...(auth?.isLoggedIn ? [] : (GTM_DATA.nonMember.slots as string[])),
      ...(GTM_DATA.mainVHP.slots as string[]),
    ],
  });

  return (
    <Layout
      FooterComponent={HomepageFooter}
      sx={{
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
      }}
      maxWidth={false}
      meta={META_PROPERTIES.mainVHP}
      pageProperties={PAGE_PROPERTIES.mainVHP}>
      <Hero />
      <Services />
      <HowItWorks />
      <Reviews />
      <RightChoice />
      <NannyHelp />
      <Guarantee />
      <Help />
    </Layout>
  );
};

export default Homepage;
