import { Grid } from '@mui/material';
import { NumberedTile } from '.';

export const HOW_IT_WORKS = [
  {
    description:
      "Set up a pay cadence that works for you, and we'll onboard your employees to save you time.",
    number: 1,
    title: 'Customized payroll process',
  },
  {
    description:
      'We prepare your quarterly and year-end taxes - filed on-time, every time. Guaranteed.',
    number: 2,
    title: 'Tax returns filed automatically',
  },
  {
    description:
      'Contact our award-winning tax, payroll & HR specialists to have your questions answered quickly and accurately.',
    number: 3,
    title: 'Unlimited access to experts',
  },
];

const Tiles = () => (
  <Grid container direction="column" data-testid="tiles-container">
    {HOW_IT_WORKS.map((tile) => (
      <Grid item sm={12} key={tile.number}>
        <NumberedTile description={tile.description} number={tile.number} title={tile.title} />
      </Grid>
    ))}
  </Grid>
);

export default Tiles;
